/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// import ShoppingCartIcon from '@heroicons/react/24/solid/ShoppingCartIcon';
import cx from 'classnames';
import BadgeCounter from '@common_badgecounter';
import Popover from '@common_popover';
import useMediaQuery from '@hook/useMediaQuery';
import CartPopover from './cartPopover';
import ProductAddedPopover from './productAddedPopover';

const WithLink = (props) => {
    const {
        cartData, handleLink, setOpenCart, openState, handleDispatch,
    } = props;
    const { isMobile } = useMediaQuery();

    return (
        <>
            <div
                onMouseEnter={() => {
                    if (!openState.isCartOpen && !isMobile) {
                        handleDispatch({ type: 'OPEN_CART' });
                    }
                }}
                onMouseLeave={() => handleDispatch({ type: 'CLOSE_CART' })}
            >
                <Popover
                    content={(
                        <CartPopover
                            open={openState.isCartOpen}
                            setOpen={setOpenCart}
                            openState={openState}
                            handleDispatch={handleDispatch}
                            {...props}
                        />
                    )}
                    open={openState.isCartOpen}
                    setOpen={setOpenCart}
                    className={cx('w-[160px]', 'top-[48px]', 'right-[-15px]')}
                    contentClassName={cx('!rounded-[5px] !border-secondary-700 shadow-type-1 max-h-fit')}
                >
                    <div
                        className={cx(
                            'group hover:cursor-pointer relative',
                            'before:w-4 before:h-4 before:bg-yellow-700 before:absolute',
                            'before:rounded-lg before:right-[-3px] before:top-[3px]',
                            'mobile:max-tablet:before:top-[-10px]',
                            'mobile:max-tablet:before:right-[-2px]',
                        )}
                        onClick={handleLink}
                    >
                        <BadgeCounter
                            className={cx(
                                '!absolute',
                                '!top-[50%]',
                                '!text-[13px]',
                                'font-medium',
                                'w-[18px]',
                                'h-[18px]',
                                '!bg-[transparent]',
                                'left-[50%]',
                                'translate-x-[-50%]',
                                '-translate-y-[5px]',
                                '!text-neutral-black',
                                'tablet:max-desktop:right-1',
                                '!text-[15px]',
                                'leading-[17px]',
                                'mobile:max-tablet:-mt-[2px]',
                            )}
                            value={cartData}
                            showZero
                        >
                            <span className="cart-icon text-neutral-black hover:before:text-neutral-350" />
                            <style jsx>
                                {`
                                .cart-icon:before {
                                    display: block;
                                    content: "\e963";
                                    font-family: var(--icons-urbanicon-fossil-theme);
                                    font-size: 43px;
                                    line-height: 43px;
                                    width: 37px;
                                    height: 43px;
                                }
                                @media only screen and (max-width: 767.9px) {
                                    .cart-icon:before {
                                        font-size: 28px;
                                        line-height: 25px;
                                        width: 25px;
                                        height: 25px;
                                    }
                                }
                            `}
                            </style>
                        </BadgeCounter>
                    </div>
                </Popover>

            </div>
            <ProductAddedPopover {...props} />
        </>
    );
};

export default WithLink;
