import React from 'react';
import cx from 'classnames';
import dynamic from 'next/dynamic';

const MiniCart = dynamic(() => import('@plugin_minicart'), { ssr: false });

const CartPopover = (props) => {
    const {
        cartData, openState,
    } = props;

    return (
        <div
            className={cx(
                'py-[20px]', 'px-0', 'min-h-fit', 'max-w-[308px]', '!rounded-[5px]', 'popover-active',
                'w-[298px]', 'after:!top-[-8px]', 'after:!right-[28px]', 'after:!w-[14px]', 'after:!h-[14px]',
            )}
        >
            <MiniCart open={openState.isCartOpen} setOpen={() => {}} count={cartData} {...props} />
        </div>

    );
};

export default CartPopover;
