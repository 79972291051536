/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';
import Popover from '@common_popover';
import cx from 'classnames';
import Typography from '@common/Typography';
import { COLORS } from '@theme_vars';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import Button from '@common/Button';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Thumbor from '@common_image';
import { formatPrice } from '@helper_currency';
import { currencyVar } from '@core/services/graphql/cache';

const ProductAdded = (props) => {
    const {
        storeConfig,
    } = props;

    const { t } = useTranslation(['common']);
    const [open, setOpen] = useState(false);
    const [product, setProduct] = useState('');
    const currencyCache = currencyVar();
    const router = useRouter();
    const addedProductRef = React.useRef(null);
    const addedProduct = typeof window !== 'undefined' && window && window.addedProduct;

    const handlingGoToCheckout = () => {
        window.addedProduct = null;
        router.push('/checkout');
    };

    const handlingGoToCart = () => {
        window.addedProduct = null;
        router.push('/checkout/cart');
    };

    const handleClick = (event) => {
        if (addedProductRef.current && !addedProductRef.current.contains(event.target)) {
            setOpen(false);
            window.addedProduct = null;
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, [open]);

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style = '';
        }
    }, [open]);

    useEffect(() => {
        if (addedProduct) {
            setOpen(true);
            setProduct(addedProduct);
        }
    }, [addedProduct]);

    const [topPosition, setTopPosition] = React.useState('');
    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            const stickyHeight = document.querySelector('#sticky-header').offsetHeight;
            if (stickyHeight > 0) {
                setTopPosition(stickyHeight);
            } else {
                setTopPosition('');
            }
        }
    }, [addedProduct]);

    const PopoverContent = () => (
        <div
            onMouseLeave={() => {}}
            className={cx(
                'p-5', 'min-h-fit', 'w-full', 'tablet:max-w-[298px]', '!rounded-[5px]', 'product-added-active',
                'w-[298px]', 'after:!top-[-7.5px]', 'tablet:after:!right-[24px]', 'after:!w-[14px]', 'after:!h-[14px]',
                'after:!right-[11.5px]',
            )}
            ref={addedProductRef}
        >
            <div className="flex flex-row justify-between mb-[30px]">
                <Typography
                    variant="p-3"
                    className="!text-2xl font-semibold !tracking-[.38px] break-words leading-[1.2]"
                >
                    {t('common:cart:addedtobag')}
                </Typography>
                <XMarkIcon
                    className={cx(
                        'cursor-pointer stroke-[3px] text-neutral-black',
                    )}
                    width={20}
                    height={20}
                    onClick={() => {
                        setOpen(false);
                        window.addedProduct = null;
                    }}
                />
            </div>

            <div className="flex flex-row gap-x-2.5 mb-5">
                <Thumbor
                    className="product-image-photo"
                    src={product.small_image.url}
                    alt={product.small_image.label}
                    width={80}
                    height={80}
                    storeConfig={storeConfig}
                    magezon={false}
                    useContainer={false}
                />
                <div className="-mt-[3px]">
                    <Typography
                        variant="bd-1b"
                        className={cx(
                            'normal-case',
                            'mobile:max-tablet:text-base',
                            'line-clamp-2',
                            '!text-neutral-black',
                            '!font-medium',
                            'text-[11px]',
                        )}
                    >
                        {product.name}
                    </Typography>
                    <div
                        className={cx(
                            'item-price',
                            'desktop:basis-[74px]',
                            '!text-neutral-black',
                            'font-bold',
                            '!text-[11px]',
                            'mt-1.5',
                        )}
                    >
                        {formatPrice(
                            product.price_range.minimum_price.final_price.value || 0,
                            product.price_range.minimum_price.final_price.currency || 'IDR',
                            currencyCache,
                        )}
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-x-[14px]">
                <Button
                    className={cx(
                        'checkout-button',
                        '!px-0',
                        '!py-[8px]',
                        '!ml-0',
                        'hover:shadow-none',
                        'focus:shadow-none',
                        'active:shadow-none',
                        'active:shadow-none',
                        '!bg-neutral-black',
                        'w-full',
                        'rounded-none',
                        'uppercase',
                        'border',
                        'border-neutrbg-neutral-black',
                        'hover:!bg-secondary-600',
                        'hover:!border-secondary-600',
                        'max-h-10',
                    )}
                    classNameText={cx('justify-center')}
                    onClick={handlingGoToCheckout}
                    variant="primary"
                    id="plugin-minicart-checkoutBtn"
                >
                    <Typography
                        variant="p-1a"
                        className={cx(
                            '!text-neutral-white',
                            '!font-bold',
                            '!text-[13px]',
                            '!leading-[14px]',
                        )}
                    >
                        {t('common:button:goCheckout')}
                    </Typography>
                </Button>
                <div
                    id="plugin-minicart-editCartBtn"
                    onClick={handlingGoToCart}
                >
                    <Typography
                        variant="p-2"
                        className={cx(
                            '!text-neutral-black',
                            'text-center',
                            'font-[500]',
                            'hover:cursor-pointer',
                            'py-[10px]',
                            'bg-neutral-white',
                            'uppercase',
                            '!font-bold',
                            // 'hover:bg-primary-900',
                            'border border-neutral-black',
                            '!text-[13px]',
                            'max-h-10',
                        )}
                    >
                        {t('common:button:viewandedit')}
                    </Typography>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {open && (
                <div
                    className={cx(
                        'bg-neutral-black bg-opacity-50 w-full h-full fixed z-10 left-0 flex',
                        'top-[70px] tablet:top-[123px]',
                    )}
                    style={{
                        ...topPosition && { top: topPosition },
                    }}
                >
                    <div className="max-w-screen-xl mx-auto w-full">
                        <Popover
                            content={<PopoverContent />}
                            open={open}
                            setOpen={() => {}}
                            className={cx(
                                'tablet:w-[160px] !top-[-6px] tablet:!top-[-18px] tablet:right-2 desktop:!top-[-18px]',
                                'desktop:right-0 !relative w-full mx-2.5 tablet:mx-0',
                            )}
                            wrapperClassName="!justify-end w-full animate-anima-slide-down"
                            contentClassName={cx('!rounded-[5px] !border-neutral-100 shadow-type-1 max-h-fit min-w-[300px]')}
                        />
                    </div>
                </div>
            )}

            <style global jsx>
                {`
                    .product-added-active:after {
                        content: '';
                        background: #fff;
                        border-bottom: 1px solid ${COLORS.neutral[100]};
                        border-right: 1px solid ${COLORS.neutral[100]};
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        transform: rotate(225deg);
                    }
                `}
            </style>
        </>
    );
};

export default ProductAdded;
